import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import LayoutContext from '../../context/LayoutContext';
import CookieZh from './CookieZh';
import CookieEn from './CookieEn';

const Container = styled.div`
  h1 {
    font-weight: bold !important;
    padding: 20px 0;
  }

  font-size: 12px;
  color: ${(props) => (props.theme.name === 'men' ? '#ffffff' : '#000000')};
  table {
    color: ${(props) => (props.theme.name === 'men' ? '#ffffff' : '#000000')};
  }

  .header-title {
    font-size: 12px;
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
    word-break: break-all;
  }

  a {
    color: ${(props) => (props.theme.name === 'men' ? '#23bbd2' : '#007bff')};
  }

  @media screen and (max-width: 767px) {
    .content {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 20px;
    }
  }
`;

const Cookie = () => {
  const { t } = useTranslation();
  const layoutProps = useContext(LayoutContext);
  const gender = layoutProps.pageContext.gender;
  const { isMobileView } = useSelector((state) => state.common);
  return (
    <Layout
      seoSettings={{ title: t('cookie_policy') }}
      fullwidth={isMobileView}
    >
      <Container>
        {layoutProps.pageContext.locale === 'zh' ? (
          <CookieZh gender={gender} isMobileView={isMobileView} />
        ) : (
          <CookieEn gender={gender} isMobileView={isMobileView} />
        )}
      </Container>
    </Layout>
  );
};

export default Cookie;
