import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';

const CookieEn = ({ gender, isMobileView }) => {
  return (
    <div>
      {isMobileView ? (
        <PageTitle shadow={gender === 'men' ? true : false}>
          BIOTHERM COOKIES POLICY
        </PageTitle>
      ) : (
        <h1>BIOTHERM COOKIES POLICY</h1>
      )}
      <div className="bootstrap page-cookie content">
        <p>
          <strong>WHAT ARE COOKIES?</strong>
          <br />
          Cookies are small text files stored on your device (computer, tablet
          or mobile) when you are on the Internet, including on L’Oréal Group’s
          websites. For more information, please consult the following links:{' '}
          <a href="http://www.aboutcookies.org">http://www.aboutcookies.org</a>.
        </p>
        <p>
          <strong>DEACTIVATION OF COOKIES </strong>
          <br />
          You can easily adjust the settings of your browser in relation of
          activation or deactivation of cookies. Please consult the Help
          Function of your browser if need be.
          <br />
          Many cookies are used to enhance the usability or functionality of
          websites/apps; therefore disabling cookies may prevent you from using
          certain parts of our websites/apps as detailed in the Cookie Table
          below.{' '}
        </p>
        <table
          border="0"
          cellSpacing="0"
          cellPadding="0"
          width="0"
          className="table table-bordered table-responsive"
        >
          <thead>
            <tr>
              <th colSpan="2">
                <strong>TYPE OF COOKIE</strong>
              </th>
              <th>
                <strong>ROLE</strong>
              </th>
              <th>
                <strong>NAME OF COOKIE </strong>
              </th>
              <th>
                <strong>WHAT IF I DO NOT ACTIVATE THEM ?</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>Strictly necessary cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong>Strictly necessary cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  Necessary for the website to function. They are usually only
                  set in response to actions made by you which amount to a
                  request for services, such as setting your privacy
                  preferences, logging in or filling in forms.
                  <br />
                  <br />
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/OptanonConsent">
                    OptanonConsent
                  </a>
                  , <br />
                  <a href="http://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed">
                    OptanonAlertBoxClosed
                  </a>
                  , <br />
                  <a href="http://cookiepedia.co.uk/cookies/SC_ANALYTICS_GLOBAL_COOKIE">
                    SC_ANALYTICS_GLOBAL_COOKIE
                  </a>
                </p>
              </td>
              <td valign="top">
                <p>These cookies cannot be deactivated.</p>
              </td>
            </tr>
            <tr>
              <td rowSpan="3" valign="top">
                <p align="center">
                  <strong>Functionality Cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong>Functionnal cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>To provide enhanced functionality and personalization.</p>
                <p>
                  They may be set by us or by third party providers whose
                  services we have added to our pages.{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/host/lo.v.liveperson.net">
                    <strong>lo.v.liveperson.net</strong>
                  </a>
                  <strong>: </strong>LPSessionID, LPVisitorID, LPSID-nnnnnnnn,
                  LPCKEY-nnnnn, LPVID
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.where-to-buy.co">
                    <strong>where-to-buy.co</strong>
                  </a>
                  <strong>: </strong>__utmz, __utmt_UA-nnnnnnnn, __utmb, __utmc,
                  __utma{' '}
                </p>
              </td>
              <td valign="top">
                <p>Some or all of these services may not function properly. </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>Session cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  Session cookies enable the website you are visiting to keep
                  track of your movement from page to page so you don't get
                  asked for the same information you've already given to the
                  site.{' '}
                </p>
                <p>
                  They are automatically deleted when you close your browser.{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/ASP.NET_SessionId">
                    ASP.NET_SessionId
                  </a>
                </p>
              </td>
              <td valign="top">
                <p>
                  Every time you open a new web page the server where that page
                  is stored will treat you like a completely new visitor.{' '}
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>Social media cookies</strong>
                </p>
              </td>
              <td valign="top">
                <ul>
                  <li>
                    To share our content with your friends and networks through
                    a range of social media services that we have added to the
                    site{' '}
                  </li>
                  <li>
                    To join/like our pages using such social media tools.{' '}
                  </li>
                </ul>
                <p>
                  They are capable of tracking your browser across other sites
                  and building up a profile of your interests. This may impact
                  the content and messages you see on other websites you visit.{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/host/cdn.syndication.twimg.com">
                    cdn.syndication.twimg.com
                  </a>
                  : lang{' '}
                </p>
                <p>
                  Below you will find links to these social network policies :
                  <br />
                  Facebook https://www.facebook.com/about/privacy/
                  <br />
                  https://www.facebook.com/help/cookies
                  <br />
                  Google + http://www.google.com/intl/fr/policies/privacy/
                  <br />
                  Twitter
                  https://support.twitter.com/articles/20170518-utilisation-des-cookies-et-des-technologies-similaires-par-twitter
                  <br />
                  Pinterest https://about.pinterest.com/en/terms-service{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  You may not be able to use or see these sharing tools and
                  join/like our pages using such social media tools.{' '}
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan="2" valign="top">
                <p align="center">
                  <strong>Targetting &amp; advertising cookies </strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong>Advertising cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  They collect data to show you personalized advertisements.
                  They also allow to limit the number of messages you will be
                  exposed to. These cookies also allow us to measure the
                  performances of our advertising campaigns, for instance the
                  Sites visited before ours.{' '}
                </p>
              </td>
              <td valign="top"></td>
              <td valign="top">
                <p>
                  • You will not be exposed to advertising messages coming from
                  us. You might not benefit from the offers dedicated to our
                  members.
                  <br />• Or, the content of advertising will not be
                  personalized. You will then have an offer less attractive to
                  you.{' '}
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>Targeting cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  To allow our advertising partners to build a profile of your
                  interests and show you relevant adverts on other sites.
                  <br />
                  <br />
                  They work by uniquely identifying your browser and internet
                  device.
                  <br />
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/icxid">icxid</a>{' '}
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.facebook.com">
                    <strong>facebook.com</strong>
                  </a>
                  <strong>: </strong>datr, x-src, fr, lu, locale
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.youtube.com">
                    <strong>youtube.com</strong>
                  </a>
                  <strong>: </strong>SID, HSID, demographics,
                  VISITOR_INFO1_LIVE, PREF, APISID, SSID, LOGIN_INFO, YSC,
                  SAPISID
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.twitter.com">
                    <strong>twitter.com</strong>
                  </a>
                  <strong>: </strong>auth_token, twll, lang, __utmz,
                  secure_session, guest_id, __utma, remember_checked,
                  remember_checked_on, __utmb,{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  You will still receive advertising on our partners’ sites but
                  it will not be targeted.{' '}
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan="2" valign="top">
                <p align="center">
                  <strong>Analytics and performance cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong>Analytics cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  To count page visits and traffic sources so we can measure and
                  improve the performance of our site, using a service provided
                  by Google Analytics.{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/_dc_gtm_UA-74428248-1">
                    _dc_gtm_UA-74428248-1
                  </a>
                  ,{' '}
                  <a href="http://cookiepedia.co.uk/cookies/_dc_gtm_UA-22588495-1">
                    _dc_gtm_UA-22588495-1
                  </a>{' '}
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.google.co.uk">
                    <strong>google.co.uk</strong>
                  </a>
                  <strong>: </strong>SAPISID, HSID, SID, PREF, NID, SSID, APISID{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  We will not be able to monitor and measure the performance of
                  our site which may result in the offering of a lower quality
                  experience.{' '}
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>Performance cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  To count visits and traffic sources to measure and improve the
                  performance of our site. To know which pages are the most and
                  least popular and see how visitors move around the site.
                  <br />
                  <br />
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/lpTestCookiennnnnnnn">
                    lpTestCookiennnnnnnn
                  </a>
                  , <br />
                  <a href="http://cookiepedia.co.uk/cookies/__olapicU">
                    __olapicU
                  </a>
                  ,<br />
                  <a href="http://cookiepedia.co.uk/cookies/_ga">_ga</a> <br />
                  <a href="http://cookiepedia.co.uk/host/lpcdn.lpsnmedia.net">
                    <strong>lpcdn.lpsnmedia.net</strong>
                  </a>
                  <strong>: </strong>lpTestCookiennnnnnnn
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.switchthelook.com">
                    <strong>switchthelook.com</strong>
                  </a>
                  <strong>: </strong>__utmc, __utmz, __utma, __utmt, __utmb{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  We will not know when you have visited our site, and will not
                  be able to monitor its performance which may result in the
                  offering of a lower quality experience.{' '}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CookieEn;
