import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';

const CookieZh = ({ gender, isMobileView }) => {
  return (
    <div>
      {isMobileView ? (
        <PageTitle shadow={gender === 'men' ? true : false}>
          COOKIE列表
        </PageTitle>
      ) : (
        <h1>COOKIE列表</h1>
      )}
      <div className="bootstrap page-cookie content">
        <p>
          <strong>COOKIES是甚麼？</strong>
          <br />
          Cookies是當閣下瀏覽互聯網，包括歐萊雅集團的網站時，儲存於閣下的裝置
          (包括桌面電腦、平板電腦或手提電話)
          裡的小型文字檔案。更多資訊，請查閱以下連結：{' '}
          <a href="http://www.aboutcookies.org">http://www.aboutcookies.org</a>
          。
        </p>
        <p>
          <strong>停用COOKIES</strong>
          <br />
          閣下可輕鬆調整閣下的瀏覽器設定，從而啟用或停用cookies。如有需要，請檢視閣下的瀏覽器的「幫助」功能。
          <br />
          許多cookies都是用以改良網站/應用程式的易用性或功能而設。因此，停用cookies可能會導致閣下無法使用我們的網站/應用程式的某些部分。詳見以下Cookies列表。
        </p>
        <table
          border="0"
          cellSpacing="0"
          cellPadding="0"
          width="0"
          className="table table-bordered"
        >
          <thead>
            <tr>
              <th colSpan="2">
                <strong>COOKIES類型</strong>
              </th>
              <th>
                <strong>功能</strong>
              </th>
              <th>
                <strong>COOKIES名稱</strong>
              </th>
              <th>
                <strong>如果我不啟用它們會怎樣？</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong style={{ wordBreak: 'keep-all' }}>
                    絕對必要
                    <br />
                    cookies
                  </strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong style={{ wordBreak: 'keep-all' }}>
                    絕對必要
                    <br />
                    cookies
                  </strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  這些cookies為網站正常運作所必需。它們通常只於回應閣下等同要求服務的動作時設置，例如設定閣下的私隱偏好、登入或填寫表格。
                  <br />
                  <br />
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/OptanonConsent">
                    OptanonConsent
                  </a>
                  , <br />
                  <a href="http://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed">
                    OptanonAlertBoxClosed
                  </a>
                  , <br />
                  <a href="http://cookiepedia.co.uk/cookies/SC_ANALYTICS_GLOBAL_COOKIE">
                    SC_ANALYTICS_GLOBAL_COOKIE
                  </a>
                </p>
              </td>
              <td valign="top">
                <p>這些cookies無法被停用。</p>
              </td>
            </tr>
            <tr>
              <td rowSpan="3" valign="top">
                <p align="center">
                  <strong>功能Cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong>功能Cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>改良網站/應用程式的功能和個人偏好設定。</p>
                <p>
                  它們可能由我們或第三方供應者設置，後者提供我們的網頁所增設的服務。
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/host/lo.v.liveperson.net">
                    <strong>lo.v.liveperson.net</strong>
                  </a>
                  <strong>: </strong>LPSessionID, LPVisitorID, LPSID-nnnnnnnn,
                  LPCKEY-nnnnn, LPVID
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.where-to-buy.co">
                    <strong>where-to-buy.co</strong>
                  </a>
                  <strong>: </strong>__utmz, __utmt_UA-nnnnnnnn, __utmb, __utmc,
                  __utma{' '}
                </p>
              </td>
              <td valign="top">
                <p>這些服務的部分或全部可能無法正常運作。</p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>工作階段cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  工作階段cookies容許閣下正瀏覽的網站記錄閣下到訪每個頁面的動態，從而令網站不會再向閣下詢問閣下已提供予網站的資訊。
                </p>
                <p>當閣下關閉瀏覽器時，它們將被自動刪除。</p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/ASP.NET_SessionId">
                    ASP.NET_SessionId
                  </a>
                </p>
              </td>
              <td valign="top">
                <p>
                  每次當閣下開啟新網頁時，儲存該頁面的伺服器將視閣下為從未曾到訪的新訪客。
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>社交媒體cookies</strong>
                </p>
              </td>
              <td valign="top">
                <ul>
                  <li>
                    透過一系列我們於網站增設的社交媒體服務，與閣下的朋友和聯繫網絡分享我們的內容
                  </li>
                  <li>利用該等社交媒體工具加入/讚好我們的網頁。</li>
                </ul>
                <p>
                  它們能夠透過其他網站追蹤閣下的瀏覽器，並建立閣下的喜好檔案。這可能會影響閣下到訪其他網站時看見的內容和信息。
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/host/cdn.syndication.twimg.com">
                    cdn.syndication.twimg.com
                  </a>
                  : lang{' '}
                </p>
                <p>
                  以下列出這些社交媒體政策的連結：
                  <br />
                  Facebook https://www.facebook.com/about/privacy/
                  <br />
                  https://www.facebook.com/help/cookies
                  <br />
                  Google + http://www.google.com/intl/fr/policies/privacy/
                  <br />
                  Twitter
                  https://support.twitter.com/articles/20170518-utilisation-des-cookies-et-des-technologies-similaires-par-twitter
                  <br />
                  Pinterest https://about.pinterest.com/en/terms-service{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  閣下可能無法使用或看見這些分享工具，以及利用該等社交媒體工具分享/讚好我們的網頁。
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan="2" valign="top">
                <p align="center">
                  <strong style={{ wordBreak: 'keep-all' }}>
                    目標及廣告
                    <br />
                    cookies
                  </strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong>廣告cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  這些cookies透過收集數據，向閣下顯示為閣下度身訂造的廣告，並容許限制閣下將接收的信息數量。此外，它們亦容許我們評估我們的廣告企劃的表現，例如閣下於瀏覽我們的網站前已到訪的網站數目。
                </p>
              </td>
              <td valign="top"></td>
              <td valign="top">
                <p>
                  •閣下將不會接收我們發出的廣告信息，惟閣下亦可能無法享受我們呈獻予會員的優惠。
                  <br />
                  •或者，廣告內容將並非為閣下度身訂造，令該優惠對閣下而言較不吸引。
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>目標cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  容許我們的廣告夥伴建立閣下的喜好檔案，並於其他網站上向閣下展示相關廣告。
                  <br />
                  <br />
                  它們透過獨立辨認閣下的瀏覽器和互聯網裝置而運作。
                  <br />
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/icxid">icxid</a>{' '}
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.facebook.com">
                    <strong>facebook.com</strong>
                  </a>
                  <strong>: </strong>datr, x-src, fr, lu, locale
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.youtube.com">
                    <strong>youtube.com</strong>
                  </a>
                  <strong>: </strong>SID, HSID, demographics,
                  VISITOR_INFO1_LIVE, PREF, APISID, SSID, LOGIN_INFO, YSC,
                  SAPISID
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.twitter.com">
                    <strong>twitter.com</strong>
                  </a>
                  <strong>: </strong>auth_token, twll, lang, __utmz,
                  secure_session, guest_id, __utma, remember_checked,
                  remember_checked_on, __utmb,{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  閣下於我們的夥伴的網站上將仍會接收廣告，惟該些廣告將並非以閣下為目標對象。
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan="2" valign="top">
                <p align="center">
                  <strong>分析及效能cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p align="center">
                  <strong>分析cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  透過利用Google
                  Analytics提供的服務，計算網頁訪問次數和流量來源，從而讓我們能夠評估和改善我們的網站的表現。
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/_dc_gtm_UA-74428248-1">
                    _dc_gtm_UA-74428248-1
                  </a>
                  ,{' '}
                  <a href="http://cookiepedia.co.uk/cookies/_dc_gtm_UA-22588495-1">
                    _dc_gtm_UA-22588495-1
                  </a>{' '}
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.google.co.uk">
                    <strong>google.co.uk</strong>
                  </a>
                  <strong>: </strong>SAPISID, HSID, SID, PREF, NID, SSID, APISID{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  我們將無法監察和評估我們的網站的表現，而這可能導致提供的體驗質素較低。
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p align="center">
                  <strong>效能cookies</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  計算我們的網站的訪問次數和流量來源，從而改善其表現，同時知悉最受歡迎和最不受歡迎的頁面為何，並觀察訪客瀏覽網站時的動態。
                  <br />
                  <br />
                </p>
              </td>
              <td valign="top">
                <p>
                  <a href="http://cookiepedia.co.uk/cookies/lpTestCookiennnnnnnn">
                    lpTestCookiennnnnnnn
                  </a>
                  , <br />
                  <a href="http://cookiepedia.co.uk/cookies/__olapicU">
                    __olapicU
                  </a>
                  ,<br />
                  <a href="http://cookiepedia.co.uk/cookies/_ga">_ga</a> <br />
                  <a href="http://cookiepedia.co.uk/host/lpcdn.lpsnmedia.net">
                    <strong>lpcdn.lpsnmedia.net</strong>
                  </a>
                  <strong>: </strong>lpTestCookiennnnnnnn
                  <br />
                  <a href="http://cookiepedia.co.uk/host/.switchthelook.com">
                    <strong>switchthelook.com</strong>
                  </a>
                  <strong>: </strong>__utmc, __utmz, __utma, __utmt, __utmb{' '}
                </p>
              </td>
              <td valign="top">
                <p>
                  我們將無法得知閣下曾到訪我們的網站，並將無法監察網站的表現，而這可能導致提供的體驗質素較低。
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CookieZh;
